<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>{{ assessment.name }}</BasePageHeading>
      </div>

      <BasePageActions>
        <EditJobButton
          :job="assessment"
          @jobUpdated="jobUpdated()"
        >
          <span>Edit Assessment</span>
        </EditJobButton>
        <ArchiveAssessmentButton
          v-if="!assessment.closedAt"
          :job="assessment"
          button-size="standard"
          @close="jobDeleted()"
        />
        <UnarchiveAssessmentButton
          v-else-if="assessment.closedAt"
          :job="assessment"
        />
        <TooltipButton>
          <template v-slot:content>
            <BaseButton
              variant="salmon"
              :disabled="!canEditExams"
              :to="{ name: 'client-assessments-exams', params: { id: assessment.uuid } }"
            >
              <template slot="iconMiddle">
                <Icon
                  view-box="0 0 24 24"
                  class="w-5 h-5"
                >
                  <ClipboardList />
                </Icon>
              </template>
            </BaseButton>
          </template>
          <template v-slot:tooltip>
            <span v-if="canEditExams">Edit Tests</span>
            <span v-else>You can’t edit tests if the assessment has already been sent</span>
          </template>
        </TooltipButton>

        <ExportButton
          :job-filter="assessment.uuid"
          type="results"
        >
          <template
            slot="trigger"
          >
            <TooltipButton>
              <template v-slot:content>
                <BaseButton
                  variant="salmon"
                >
                  <template slot="iconMiddle">
                    <Icon
                      width="16px"
                      height="16px"
                      view-box="0 0 512 512"
                    >
                      <Download />
                    </Icon>
                  </template>
                </BaseButton>
              </template>
              <template v-slot:tooltip>
                Export assessment data
              </template>
            </TooltipButton>
          </template>
        </ExportButton>

        <OpenSignUpJobButton
          :job="assessment"
        />
      </BasePageActions>
    </BasePageHeader>

    <div
      v-if="allExams.length"
      class="max-w-screen-xl px-6 mx-auto mb-6 -mt-4 overflow-x-scroll md:overflow-x-visible"
    >
      <ul class="flex flex-wrap max-w-3xl gap-3">
        <li
          v-for="exam in allExams"
          :key="exam.slug"
          class="inline-flex rounded items-center py-1.5 px-2.5 text-sm font-medium bg-gray-50 border border-secondary text-secondary"
        >
          {{ exam.name }}
        </li>
      </ul>
    </div>

    <BasePageRefine class="space-y-4 sm:space-y-0">
      <SearchBox
        placeholder="Search candidates"
        @search="searchCandidates($event)"
      />
      <!-- @TODO 2023-02-07 Remove after some time if not use -->
      <!-- <CustomSelect
        placeholder="Status"
        :options="statuses"
        @selected="filterStatus($event)"
      /> -->
    </BasePageRefine>

    <BaseTabBar>
      <BaseTabs>
        <BaseTab
          :to="{ name: 'client-assessments-show', params: { id: assessment.uuid } }"
          :active="true"
        >
          Candidates
        </BaseTab>
        <BaseTab
          v-if="(assessment.attemptsAverageScorePercentage || assessment.attemptsAverageMinutesCompleted)"
          :to="{ name: 'client-assessments-analytics', params: { id: assessment.uuid } }"
        >
          Analytics
        </BaseTab>
      </BaseTabs>
    </BaseTabBar>

    <BaseWrapper v-if="!loadingTableContent && candidates.length === 0 && !searchQuery && !status">
      <AssessmentEmpty>
        <div v-if="assessment.exams && assessment.exams.length > 0">
          <p class="mb-6 text-gray-600 text-md">
            You can edit the tests in this assessment up until the first
            candidate takes a test. Once you’re ready, share this link with
            your candidates, or send an invitation:
          </p>
          <div class="mt-10">
            <OpenSignUpJobButton
              :job="assessment"
            />
          </div>
        </div>
        <div v-if="!assessment.exams || assessment.exams.length === 0">
          <p class="mb-6 text-gray-600 text-md">
            You can edit the tests in this assessment up until the first
            candidate takes a test. Add some tests now before you invited
            your first candidate:
          </p>
          <div class="mt-10">
            <BaseButton
              variant="salmon"
              :disabled="!canEditExams"
              :to="{ name: 'client-assessments-exams', params: { id: assessment.uuid } }"
            >
              Edit Tests
            </BaseButton>
          </div>
        </div>
      </AssessmentEmpty>
    </BaseWrapper>
    <BaseWrapper v-else-if="!loadingTableContent && candidates.length === 0 && searchQuery">
      <p class="py-6 text-center bg-white">
        No candidates can be found searching for “{{ searchQuery }}”.
      </p>
    </BaseWrapper>
    <BaseWrapper v-else-if="!loadingTableContent && candidates.length === 0 && status">
      <p class="py-6 text-center bg-white">
        No candidates can be found match the current filter.
      </p>
    </BaseWrapper>
    <BaseWrapper v-if="candidates.length > 0">
      <BaseTable fixed>
        <BaseTHead>
          <tr>
            <BaseTHeadTh>
              <BaseTableSortBy
                name="name"
                :sort-by="sortBy"
                :sort-order="sortOrder"
                @sortBy="changeSortBy($event)"
                @sortOrder="changeSortOrder($event)"
              >
                Name
              </BaseTableSortBy>
            </BaseTHeadTh>
            <BaseTHeadTh
              align="right"
            >
              <BaseTableSortBy
                name="average_score_percentage"
                :sort-by="sortBy"
                :sort-order="sortOrder"
                @sortBy="changeSortBy($event)"
                @sortOrder="changeSortOrder($event)"
              >
                Score
              </BaseTableSortBy>
            </BaseTHeadTh>
            <BaseTHeadTh align="right">
              <BaseTableSortBy
                name="created_at"
                :sort-by="sortBy"
                :sort-order="sortOrder"
                @sortBy="changeSortBy($event)"
                @sortOrder="changeSortOrder($event)"
              >
                Added
              </BaseTableSortBy>
            </BaseTHeadTh>
            <!-- @TODO 2023-02-07 Remove if not used after some time -->
            <!-- <BaseTHeadTh align="right">
              Status
            </BaseTHeadTh> -->
            <BaseTHeadTh
              align="right"
              class="sr-only"
            >
              Actions
            </BaseTHeadTh>
          </tr>
        </BaseTHead>
        <BaseTBody>
          <tr
            v-for="candidate in candidates"
            :key="candidate.uuid"
          >
            <BaseTBodyTh>
              <router-link
                v-if="!loadingTableContent"
                :to="{ name: 'client-assessments-candidate', params: { id: assessment.uuid, candidate: candidate.uuid } }"
              >
                {{ candidate.firstName }}
                {{ candidate.surname }}
              </router-link>
              <div
                v-else
                class="w-full h-8 bg-gray-100"
              >
              </div>
            </BaseTBodyTh>
            <BaseTBodyTd align="right">
              <template v-if="!loadingTableContent">
                {{ averageScore(candidate) }}
              </template>
              <div
                v-else
                class="w-full h-8 bg-gray-100"
              >
              </div>
            </BaseTBodyTd>

            <BaseTBodyTd align="right">
              <template v-if="!loadingTableContent">
                {{ formatDate(candidate.createdAt) }}
              </template>
              <div
                v-else
                class="w-full h-8 bg-gray-100"
              >
              </div>
            </BaseTBodyTd>
            <!-- @TODO 2023-02-07 Remove if not used after some time -->
            <!-- <BaseTBodyTd align="right">
              {{ statusMap(candidate.status) }}
            </BaseTBodyTd> -->
            <BaseTBodyTd align="right">
              <div class="flex justify-end">
                <TooltipButton>
                  <template v-slot:content>
                    <BaseButton
                      variant="salmon"
                      size="small"
                      :to="{ name: 'client-assessments-candidate', params: { id: assessment.uuid, candidate: candidate.uuid } }"
                    >
                      <template slot="iconMiddle">
                        <Icon
                          view-box="0 0 24 24"
                          class="w-5 h-5"
                        >
                          <Eye />
                        </Icon>
                      </template>
                    </BaseButton>
                  </template>
                  <template v-slot:tooltip>
                    View results
                  </template>
                </TooltipButton>
              </div>
            </BaseTBodyTd>
          </tr>
        </BaseTBody>
      </BaseTable>

      <Pagination
        :pagination="meta"
        @goToPage="changePage($event)"
      />
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
      <BasePageActions>
        <BaseButton :disabled="true">
          <template slot="iconLeft">
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5"
            >
              <PaperAirplane />
            </Icon>
          </template>
          Invite Candidates
        </BaseButton>
      </BasePageActions>
    </BasePageHeader>
    <BaseLoader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
// Components
import ArchiveAssessmentButton from '@components/ClientAssessments/ArchiveAssessmentButton'
import AssessmentEmpty from '@components/ClientAssessments/AssessmentEmpty'
import EditJobButton from '@components/Jobs/EditJobButton'
import OpenSignUpJobButton from '@components/Jobs/OpenSignUpJobButton'
import UnarchiveAssessmentButton from '@components/ClientAssessments/UnarchiveAssessmentButton'
import Pagination from '@components/TUI/Pagination'
import TooltipButton from '@components/TooltipButton'
import ClipboardList from '@components/Icons/ClipboardList'
import Icon from '@components/Icons/Icon'
import Eye from '@components/Icons/Eye'
import SearchBox from '@components/TUI/SearchBox'
// @TODO 2023-02-07 Remove after some time if not use
// import CustomSelect from '@components/CustomSelect'
import ExportButton from '@components/ExportButton'
import Download from '@components/Icons/Download'
import PaperAirplane from '@components/Icons/PaperAirplane'

// Utilities
import jobCandidatesApi from '@api/jobCandidates'
import states from '@api/states'
import { formatDate } from '@utils/formatDate'
import { mapGetters } from 'vuex'

export default {
  components: {
    ArchiveAssessmentButton,
    AssessmentEmpty,
    EditJobButton,
    OpenSignUpJobButton,
    UnarchiveAssessmentButton,
    Pagination,
    TooltipButton,
    ClipboardList,
    Icon,
    Eye,
    SearchBox,
    // @TODO 2023-02-07 Remove after some time if not use
    // CustomSelect,
    ExportButton,
    Download,
    PaperAirplane
  },

  data() {
    return {
      states,
      formatDate,
      pageNumber: 1,

      sortBy: 'created_at',
      sortOrder: 'desc',
      searchQuery: null,
      status: null,
      loadingTableContent: false,

      error: null,
      candidates: null,
      meta: null,
      assessment: null,

      statuses: [
        { name: 'Completed', uuid: 'completed' },
        { name: 'In progress', uuid: 'in_progress' }
      ]

    }
  },

  page() {
    return {
      title: this.assessmentName
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        },
        {
          name: 'Assessments',
          to: 'client-assessments-list'
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.candidates) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {string}
     */
    assessmentName() {
      if (!this.assessment) {
        return ''
      }
      return this.assessment.name
    },

    /**
     * @return {Boolean}
     */
    canEditExams() {
      if (!this.candidates) {
        return false
      }
      return this.candidates.length === 0
    },

    /**
     * @return {Array}
     */
    allExams() {
      return [...this.assessment.exams, ...this.assessment.customExams]
    }
  },

  created() {
    this.fetchAssessmentCandidates()
  },

  methods: {
    /**
     * @TODO 2023-02-07 Remove after some time if not use
     *
     * Status name map
     *
     * @param {string} status
     */
    // statusMap(status) {
    //   const statuses = {
    //     'NOT_STARTED': 'Not started',
    //     'IN_PROGRESS': 'In progress',
    //     'COMPLETED': 'Completed'
    //   }
    //   return statuses[status]
    // },

    /**
     * Fetch the job profile based on the job id
     */
    fetchAssessmentCandidates() {
      this.loadingTableContent = true

      return jobCandidatesApi.index(
        this.$route.params.id,
        this.pageNumber,
        this.sortBy,
        this.sortOrder,
        this.searchQuery,
        this.status
      )
        .then(response => {
          this.loadingTableContent = false

          this.candidates = response.data
          this.meta = response.meta
          this.assessment = response.job
        })
        .catch(error => {
          this.loadingTableContent = false

          this.error = error
          throw error
        })
    },

    /**
     * Trigger a change
     *
     * @param {string} field
     */
    changeSortBy(field) {
      this.sortBy = field

      this.fetchAssessmentCandidates()
    },

    /**
     * Trigger a change
     *
     * @param {string} order
     */
    changeSortOrder(order) {
      this.sortOrder = order

      this.fetchAssessmentCandidates()
    },

    /**
     * Change page
     *
     * @param {Number} pageNumber
     */
    changePage(pageNumber) {
      this.pageNumber = pageNumber

      this.fetchAssessmentCandidates()
    },

    /**
     * Replace search query and trigger fetch attempts
     *
     * @param {string}
     */
    searchCandidates(query) {
      this.searchQuery = query

      this.fetchAssessmentCandidates()
    },

    /**
     * @TODO 2023-02-07 Remove after some time if not use
     *
     * Filter has been chosen for a status
     *
     * @param {Object} exam
     */
    // filterStatus(status) {
    //   this.status = status.id
    //   this.fetchAssessmentCandidates()
    // },

    /**
     * A job has been deleted
     */
    jobDeleted() {
      this.$router.push({ name: 'jobs' })
    },

    /**
     * A job has been updated
     */
    jobUpdated() {
      this.fetchAssessmentCandidates()
    },

    /**
     * Returns a string of the ‘score’ (most likely a percentage) for the given
     * exam and candidate
     *
     * @param {Object} candidate
     * @param {Object} exam
     * @return {string}
     */
    findScoreForCandidateExam(candidate, exam) {
      if (!candidate.attempts || candidate.attempts.length === 0) {
        return '–'
      }

      const attempt = candidate.attempts.find(attempt => attempt.examSlug === exam.slug)

      if (!attempt || attempt.scorePercentage === null) {
        return '–'
      }

      return `${attempt.scorePercentage}%`
    },

    /**
     * Returns average score for all attempts
     *
     * @param {Object} candidate
     * @return {string}
     */
    averageScore(candidate) {
      if (!candidate.attempts || candidate.attempts.length === 0) {
        return '–'
      }

      const scorePercentages = candidate.attempts
        .filter(attempt => attempt.unlockedAt)
        .filter(attempt => attempt.scorePercentage)
        .map(attempt => attempt.scorePercentage)

      // Caters for personality exams etc
      if (scorePercentages.length === 0) {
        return '–'
      }

      const average = Math.round(scorePercentages.reduce((a, b) => a + b) / scorePercentages.length)

      return `${average}%`
    }
  }
}
</script>
